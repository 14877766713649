import React  from 'react';
import './Connect.css';
import { Player } from "@lottiefiles/react-lottie-player";
import connect from "../Assets/connect/connect.json";
import { Container, Row, Col} from 'react-bootstrap';

function Connect() {
  
    return (
        <div>
            <section className="connectMain">
                <Container>
                    <Row>
                        <Col className="mView" xs={12} md={6} lg={6} xl={6}>
                            <div className="animationDiv">
                                <Player className="heightAdjustC" autoplay loop src={connect}></Player>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={6} xl={6}>
                            <h1 className="connectMainHead">Let’s create something <span className="highlightConnect">amazing together</span></h1>
                            <p className="connectSub">Have an idea to share with us? Say hello to..</p>
                            <div className="ConnectStyle">
                                <a href="mailto:chetan@codecartel.in" className="connectLink">
                                info@codecartel.in
                                </a>
                            </div>
                            <div className="spaceTel">
                                <a href="tel:+917000661697" className="connectLink">
                                    +91 70006 61697
                                </a>
                            </div>
                            <p className="addressConnect">#6, New Market, STT Nagar, TT Nagar, Bhopal, Madhya Pradesh 462003</p>
                        </Col>
                        <Col className="dView" xs={12} md={6} lg={6} xl={6}>
                            <div className="animationDiv">
                                <Player className="heightAdjustC" autoplay loop src={connect}></Player>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Connect