import React from 'react'
import './Footer.css'
import { Player } from "@lottiefiles/react-lottie-player";
import Linkedin from "../Assets/social/linkedin.png";
import executer from "../Assets/footer/executer.json";
import Instagram from "../Assets/social/instagram.png";
import Facebook from "../Assets/social/facebook.png";
import Twitter from "../Assets/social/twitter.png";
import { Container, Row, Col} from 'react-bootstrap';
function Footer() {
  
    return (
        <div>
          {/* <div className="clients-slideshow-footer slideright-f"></div> */}
        <section className="footer">
          <Container>
            <div className="fSpace">
              <Row>
                <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                  <p className="footerCta">Let’s Create Something Amazing Together</p>
                  <div className="alignLeftFooter">
                    <a href="/Connect" className="blackBtn">LET'S CONNECT</a>
                  </div>
                </Col>
                <Col sm={4} xs={4} md={2} lg={2} xl={2}>
                  <p className="footerLinksHead">Menu</p>
                  <p className="linksFooter">
                    <a href="/about" className="linksFooter">About</a>
                  </p>
                  <p className="linksFooter">
                    <a href="/comingsoon" className="linksFooter">Work</a>
                  </p>
                  {/* <p className="linksFooter">
                    <a href="/ComingSoon" className="linksFooter">Learn</a>
                  </p> */}
                  <p className="linksFooter">
                    <a href="/connect" className="linksFooter">Connect</a>
                  </p>
                </Col>
                <Col sm={8} xs={8} md={3} lg={3} xl={3}>
                  <p className="footerLinksHead">Services</p>
                  <p className="linksFooter">
                    <a href="/services" className="linksFooter">User Experience Design</a>
                  </p>
                  <p className="linksFooter">
                    <a href="/services" className="linksFooter">User Interface Design</a>
                  </p>
                  <p className="linksFooter">
                    <a href="/services" className="linksFooter">Branding</a>
                  </p>
                  <p className="linksFooter">
                    <a className="linksFooter" href="/services">Development</a>
                  </p>
                  <p className="linksFooter">
                    <a href="/services" className="linksFooter">Marketing</a>
                  </p>
                  {/* <p className="linksFooter">
                    <a href="/Services" className="linksFooter">Support & Maintenance</a>
                  </p> */}
                </Col>
                <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                  <p className="footerLinksHead">Follow</p>
                  <div className="alignLeftF">
                    <a rel="noreferrer" href="https://www.linkedin.com/in/code-cartel-b54137223" target="_blank">
                      <img className="socialIconStyle" src={Linkedin} alt="linkedin - codecartel"></img>
                    </a>
                    <a rel="noreferrer" href="https://www.facebook.com/Codecartel-101069365799627" target="_blank">
                      <img className="socialIconStyle" src={Facebook} alt="facebook - codecartel"></img>
                    </a>
                    <a rel="noreferrer" href="https://instagram.com/codecartelin?utm_medium=copy_link" target="_blank">
                      <img className="socialIconStyle" src={Instagram} alt="instagram - codecartel"></img>
                    </a>
                    <a rel="noreferrer" href="https://twitter.com/codecartelin?t=HuXCZaGgnA1hOxuTt22orQ&s=08" target="_blank">
                      <img className="socialIconStyle" src={Twitter} alt="twitter - codecartel"></img>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>  
            <div className="bicycle bicycle-animation">
              <Player className="adjustAnimate"
                style={{ width: "200px", height: "200px" }}
                autoplay
                loop
                src={executer}
              ></Player>
            </div>
          </Container>
          <div className="lineFooter">
            <Container>
              <Row>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                  <p className="footerLogoTxt">Codecartel.</p>
                  <p className="taglineFooter">
                    Creative Design + Development Studio
                  </p>
                </Col>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                  <p className="footerLogoTxtRight">
                    6, New Market, STT Nagar, TT Nagar, Bhopal, <br></br>
                    Madhya Pradesh 462003
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </section> 
        </div>
    )
}

export default Footer