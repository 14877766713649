import React, { useEffect, useRef }  from 'react';
import './Services.css';
import { Player } from "@lottiefiles/react-lottie-player";
import strategy from "../Assets/services/strategy.svg";
import design from "../Assets/services/design.svg";
import { Container, Row, Col} from 'react-bootstrap';
import development from "../Assets/services/development.svg";
import uxtxt from "../Assets/services/uxtxt.svg";
import uitxt from "../Assets/services/uitxt.svg";
import brandingtxt from "../Assets/services/brandingtxt.svg";
import uxdesign from "../Assets/services/uxdesign.json";
import uidesign from "../Assets/services/uidesign.json";
import branding from "../Assets/services/branding.json";
import developmentAnimate from "../Assets/services/development.json";
import marketing from "../Assets/services/marketing.json";
import devtxt from "../Assets/services/devtxt.svg"
// import walkingSpider from "../Assets/services/walkingspider.json";
// gsap 
import { gsap } from "gsap";


function Services() {
    const boxRef = useRef();
  // wait until DOM has been rendered
  useEffect(() => { 
    gsap.to(boxRef.current, { rotation: "+=360" });
  });
 
    return (
        <div>
            <section className='divMain'>
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12} xl={12}>
                            <div className='boxShadowService'>
                                <h2 className='startMainHead'>Services we offer.</h2>
                                <p className='pageSubPara'>In a world where everything is digital, there is very little that truly stands out. Codecartel is all about creating incredible designs and functional digital products that really make you and your business stand out.</p>
                            </div>
                            {/* Originality, creativity, and performance are what will get you to the highest levels. */}
                        </Col>
                        {/* <Col xs={12} md={3} lg={3} xl={3}>
                            <div>
                                <Player id="firstLottie" className="styleCube" autoplay loop src={"https://assets5.lottiefiles.com/packages/lf20_r1u8s9oh.json"}></Player>
                            </div>
                        </Col> */}
                    </Row>
                    <Row className='serviceTopSpace'>
                        <Col xs={12} md={4} lg={4} xl={4}>
                            <div className='serviceCardUp'>
                                <img src={strategy} className="styleImgSc" alt="strategy"></img>
                                <p className='cardHeadS'>Strategy</p>
                                <p className='paraSer'>Ideating, formulating and researching business specific digital and design solutions.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4}>
                        <div className='serviceCardUp mSpaceC'>
                            <img src={design} className="styleImgSc" alt="strategy"></img>
                            <p className='cardHeadS'>Design</p>
                            <p className='paraSer'>Creating a striking digital identity with a special focus on UI and UX.</p>
                        </div>
                        </Col>
                        <Col xs={12} md={4} lg={4} xl={4}>
                        <div className='serviceCardUp'>
                            <img src={development} className="styleImgSc" alt="strategy"></img>
                            <p className='cardHeadS'>Development</p>
                            <p className='paraSer'>Building integrated digital products using the latest technology and tools.</p>
                        </div>
                        </Col>
                    </Row>
                     
                </Container>
                {/* <div>
                    <Player className="walkingSpider" autoplay loop src={"https://assets6.lottiefiles.com/packages/lf20_jkzaufsd.json"}></Player>
                </div> */}
            </section>
            <section id="uxDesign" className='bgWhiteService'>
                <Container>
                    <div className='s-40'>
                        <img src={uxtxt} className="txtService" alt='sleek empathised usable'></img>
                    </div>
                    <div>
                        <Row>
                            <Col className='Mview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate mViewUx" autoplay loop src={uxdesign}></Player>
                            </Col>
                            <Col className='styleMInfo' xs={12} md={6} lg={6} xl={6}>
                                <p className='serviceHead'>User Experience Design</p>
                                <p className='servicePara'>How your visitors engage with the content on your website is important to us. We’ll wireframe your new website to map out a clear user journey, from the very first impression, to how they navigate the pages and content. This helps us to design and develop a site that works hard for your brand without frustrating your users.</p>
                                <Row>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>User Research</p>
                                        <p className='servicePonits'>User flow</p>
                                        <p className='servicePonits'>IA Validation</p>
                                        <p className='servicePonits'>UX/UI Consulting</p>
                                        <p className='servicePonits'>Wireframes and prototyping</p>
                                    </Col>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Heuristic Evaluation</p>
                                        <p className='servicePonits'>Persona Development</p>
                                        <p className='servicePonits'>User & Market Research</p>
                                        <p className='servicePonits'>User Journey Mapping</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='Dview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate" autoplay loop src={uxdesign}></Player>
                            </Col>
                        </Row>
                    </div>
                       
                </Container>
            </section>
            {/* <div className="box" ref={boxRef}>Hello</div> */}
            <section id="uiDesign" className='bgOffService'>
                <Container>
                    <div className='s-40'>
                        <img src={uitxt} className="txtService" alt='sleek empathised usable'></img>
                    </div>
                    <div>
                        <Row>
                            <Col xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate mStyleUx" autoplay loop src={uidesign}></Player>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6}>
                                <p className='serviceHead'>User Interface Design</p>
                                <p className='servicePara'>Our digital products and websites are designed in detail not only to look great but to work across devices and platforms. Add interactions and touchpoints that are designed to draw users through a journey, and the result is a digital representation of your business that is beautiful, functional, and will generate more leads.</p>
                                <Row>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>User Interface Design</p>
                                        <p className='servicePonits'>Interaction Design</p>
                                        <p className='servicePonits'>Digital Brand Development</p>
                                        <p className='servicePonits'>Rapid Prototyping</p>
                                        <p className='servicePonits'>Design Systems</p>
                                    </Col>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Styleguide</p>
                                        <p className='servicePonits'>Visual Design</p>
                                        <p className='servicePonits'>Icon Design</p>
                                        <p className='servicePonits'>App and product design</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                       
                </Container>
            </section>
            <section id="branding" className='bgWhiteService'>
                <Container>
                    <div className='s-40'>
                        <img src={brandingtxt} className="txtService" alt='sleek empathised usable'></img>
                    </div>
                    <div>
                        <Row>
                            <Col className='Mview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate" autoplay loop src={branding}></Player>
                            </Col>
                            <Col className='brandSpaceM' xs={12} md={6} lg={6} xl={6}>
                                <p className='serviceHead'>Branding</p>
                                <p className='servicePara'>Brands are ideas that keep growing. We think of them as machine learning. When you build a brand, you build in the power to adapt and evolve. We create the building blocks: the strategy, symbol, logotype, typography, color scheme, iconography, illustration style, visuals, animations, motion design, photography style, sound design, messaging, and tone of voice. But ultimately the brand creates itself – in the minds and hearts of the audience.</p>
                                <Row>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Logo and identity design</p>
                                        <p className='servicePonits'>Brand strategy</p>
                                        <p className='servicePonits'>Print collateral</p>
                                        <p className='servicePonits'>Marketing plans</p>
                                    </Col>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Rebranding</p>
                                        <p className='servicePonits'>Persona Development</p>
                                        <p className='servicePonits'>Illustrations</p>
                                        <p className='servicePonits'>Brand handbook</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='Dview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="brandingAnimate" autoplay loop src={branding}></Player>
                            </Col>
                        </Row>
                    </div>
                       
                </Container>
            </section>
            <section id="development" className='bgOffService'>
                <Container>
                    <div className='s-40'>
                        <img src={devtxt} className="txtService" alt='sleek empathised usable'></img>
                    </div>
                    <div>
                        <Row>
                            <Col xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate devMview" autoplay loop src={developmentAnimate}></Player>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6}>
                                <p className='serviceHead'>Development</p>
                                <p className='servicePara'>Since we’re creative thinkers, our code is always customized. We leverage custom development to make sure your website is supported on all browsers/devices with fun and engaging interactions. Our services are flexible for both your users and your business. We provide seamless integrations and automation to meet your marketing goals, a precise content management system for your growing business, and SEO optimization because we want you to be first.</p>
                                <Row>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Custom Web Development</p>
                                        <p className='servicePonits'>Mobile App Development</p>
                                        <p className='servicePonits'>Web Apps</p>
                                        <p className='servicePonits'>Content Management System</p>
                                        <p className='servicePonits'>eCommerce Development</p>
                                    </Col>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>Support & Maintenance</p>
                                        <p className='servicePonits'>Front-end development</p>
                                        <p className='servicePonits'>Shopify, WordPress, Wix, Webflow etc.</p>
                                        <p className='servicePonits'>CRM</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                       
                </Container>
            </section>
            <section id="marketing" className='bgWhiteService'>
                <Container>
                    <div className='s-40'>
                        <img src={brandingtxt} className="txtService" alt='sleek empathised usable'></img>
                    </div>
                    <div>
                        <Row>
                            <Col className='Mview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="uxDesignAnimate markMview" autoplay loop src={marketing}></Player>
                            </Col>
                            <Col xs={12} md={6} lg={6} xl={6}>
                                <p className='serviceHead'>Marketing</p>
                                <p className='servicePara'>Our goal is not just to get visitors to your site, but to get the right type of visitors to your site. We take a 360 digital approach to marketing that starts with research and strategy, where we help you develop valuable and compelling content that suits your brand and connects with your target audience. With the right marketing tools, we'll effectively capture, engage, and convert your target audience and grow your business.</p>
                                <Row>
                                
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                        <p className='servicePonits'>SEO</p>
                                        <p className='servicePonits'>Google Ads</p>
                                        <p className='servicePonits'>Social Media Management</p>
                                       
                                    </Col>
                                    <Col xs={6} md={6} lg={6} xl={6}>
                                    <p className='servicePonits'>Email Marketing</p>
                                        <p className='servicePonits'>Digital Marketing</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='Dview' xs={12} md={6} lg={6} xl={6}>
                                <Player className="brandingAnimateMarketing" autoplay loop src={marketing}></Player>
                            </Col>
                        </Row>
                    </div>
                       
                </Container>
            </section>
        </div>
    )
}

export default Services