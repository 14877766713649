import React  from 'react'
import './ComingSoon.css'
import { Player } from "@lottiefiles/react-lottie-player";
import comingSoon from "../Assets/comingSoon/comingSoon.json";
import { Container, Row, Col } from 'react-bootstrap';

function ComingSoon() {
  
    return (
        <div>
            <section className="comingSoonMain">
                <Container>
                <Row>
                <Col className="mobileShow" xs={12} md={7} lg={7} xl={7}>
                        <div>
                            <Player className="heightAdjustCA" autoplay loop src={comingSoon}></Player>
                        </div> 
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5}>
                        <div className="spaceComing">
                            <h1 className="webPageHead">WebPage Under <span className="colorChangeCS">Construction</span></h1>
                            <p className="paraTxtCS">Visuals coming soon </p>
                        </div>
                        <div className="spaceBtn">
                            <a href="/" className="whiteBtn">BACK TO HOME</a>
                        </div>
                    </Col>
                    <Col className="desktopShow" xs={12} md={7} lg={7} xl={7}>
                        <div>
                            <Player className="heightAdjustCA" autoplay loop src={comingSoon}></Player>
                        </div> 
                    </Col>
                </Row>
                </Container>
            </section>
        </div>
    )
}

export default ComingSoon