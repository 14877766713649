import React from "react";
import mainCube from "../Assets/home/mainCube.svg";
import teamIllustrations from "../Assets/home/team_illustrations.svg";
import paperCut from "../Assets/home/paperLayer.png";
import aboutIllustration from "../Assets/home/codecartel_illustration01.svg";
import illustration01 from "../Assets/whatWeDo/illustration01.svg";
import illustration02 from "../Assets/whatWeDo/illustration02.svg";
import illustration03 from "../Assets/whatWeDo/illustration03.svg";
import illustration04 from "../Assets/whatWeDo/illustration04.svg";
import illustration05 from "../Assets/whatWeDo/illustration05.svg";
import illustration06 from "../Assets/whatWeDo/illustration06.svg";
import illustration07 from "../Assets/whatWeDo/illustration07.svg";
import uxDesign from "../Assets/ourServiceIcons/uxdesign.svg";
import uiDesign from "../Assets/ourServiceIcons/uidesign.svg";
import Development from "../Assets/ourServiceIcons/development.svg";
import Branding from "../Assets/ourServiceIcons/branding.svg";
import Marketing from "../Assets/ourServiceIcons/marketing.svg";
import Quote from "../Assets/home/quote.svg";
import mobileIllustration from "../Assets/home/mobileIllustration.png";
import cardSuperPower1 from "../Assets/superpowers/cardSuperPower1.svg";
import cardSuperPower2 from "../Assets/superpowers/cardSuperPower2.svg";
import cardSuperPower3 from "../Assets/superpowers/cardSuperPower3.svg";
import right from "../Assets/superpowers/right.svg";
import left from "../Assets/superpowers/left.svg";
import stand from "../Assets/superpowers/stand.svg";
import "./Home.css";
import {
  Nav,
  Container,
  Row,
  Col,
  Tab,
  Carousel,
} from "react-bootstrap";

function Home() {
 
  return (
    <div>
      <section className="firstFold">
        <Container>
          <div>
            <div>
              <img
                data-aos="zoom-in"
                src={mainCube}
                alt="codecartel main cube icon"
                fluid
              ></img>
            </div>
            <p
              data-aos="slide-down"
              className="MainHeadSmall"
            >
              Born Digital at the intersection of
            </p>
            <h1
              data-aos="fade"
              className="mainHeadBig"
            >
              Creativity <span className="outlineStroke">&</span> Technology
            </h1>
            <p
              data-aos="slide-up"
              className="MainHeadLower"
            >
              Strategy, Design, and Development across all platform
            </p>
          </div>
          <div className="btnSpaceTop">
          <a href="/Connect" className="whiteBtnBg">GET STARTED</a>
          </div>
        </Container>
        {/* <div>
          <div className="clouds">
            <img src={Cloud1} className="cloud1 cloud-ani1"></img>
            <img src={Cloud2} className="cloud2 cloud-ani2"></img>
            <img src={Cloud3} className="cloud3 cloud-ani3"></img>
            <img src={Cloud4} className="cloud4 cloud-ani4"></img>
          </div>
        </div> */}
      </section>

      <div className="avatarsImageBox">
        <img
          data-aos="fade"
          src={teamIllustrations}
          alt="codecartel teamIllustrations"
          className="teamImageStyle"
          fluid
          title="team illustration"
        ></img>
      </div>

      <section className="secondFold">
        <div className="paper-slider">
          <div className="paper-slide-track">
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder"  title="borderup website" className="img-fluid"></img>
            </div>
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder" title="borderup website" className="img-fluid"></img>
            </div>
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder" title="borderup website" className="img-fluid"></img>
            </div>
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder" title="borderup website" className="img-fluid"></img>
            </div>
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder" title="borderup website" className="img-fluid"></img>
            </div>
            <div className="paper-slide">
              <img src={paperCut} alt="paperborder" title="borderup website" className="img-fluid"></img>
            </div>
          </div>
        </div>
        <div className="secondFoldBg">
          <Container className="topBottom">
            <div>
              <Row className="paddingMainSecond">
              <Col className="mobileViewShow" xs={12} md={6} lg={6} xl={6}>
                  <div
                    data-aos="fade"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-cubic"
                    data-aos-anchor-placement="top-center"
                  >
                    <img
                      src={aboutIllustration}
                      className="img-fluid styleImage"
                      fluid
                      alt="about codecartel"
                      title="codecartel team"
                    ></img>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} xl={6}>
                  <div className="spaceTop">
                    <h2  data-aos="fade"
                        data-aos-duration="2000" className="mainHead displayBelow">
                      Who we are
                      {/* <img src={lineFirst} alt="line"></img> */}
                    </h2>
                    <p data-aos="fade"
                      data-aos-duration="2000" className="mainBodyPara">
                      CodeCartel is a strategically driven, digital-first agency
                      that lives at the intersection of Creativity & Technology.
                      We solve consumer and business problems with end-to-end
                      solutions that flex to meet the needs of today’s
                      ever-changing digital landscape.
                    </p>
                    <div className="alignLeft">
                    <a href="/about" className="blackBtn">KNOW MORE</a>
                    </div>
                  </div>
                </Col>

                <Col className="desktopViewShow" xs={12} md={6} lg={6} xl={6}>
                  <div
                    data-aos="fade"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-cubic"
                    data-aos-anchor-placement="top-center"
                  >
                    <img
                      src={aboutIllustration}
                      className="img-fluid styleImage"
                      fluid
                      alt="about codecartel"
                      title="codecartel team"
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>

      <section className="thirdFold">
        <div>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                  <Tab.Content className="desktopViewShow">
                    <Tab.Pane eventKey="first">
                      <div
                        data-aos="fade"
                        data-aos-easing="ease-in"
                        className="alignLeftSecond"
                      >
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration01}
                          className="img-fluid styleWwd"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration02}
                          className="img-fluid styleWwd"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration03}
                          className="img-fluid styleWwd3"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration04}
                          className="img-fluid styleWwd4"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration05}
                          className="img-fluid styleWwd5"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration06}
                          className="img-fluid styleWwd6"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          title="codecartel we do"
                          src={illustration07}
                          className="img-fluid styleWwd7"
                        ></img>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="spaceBottomSecond">
                    <h2 className="mainHeadLight">What we do</h2>
                    <p className="subheadSecond">
                      We are a{" "}
                      <span className="bold">
                        Creative Design + Development studio
                      </span>
                      , offering state-of-the-art web development services with
                      top-notch design skills and technical knowledge.
                    </p>
                  </div>
                  {/*  */}
                  <Tab.Content className="mobileViewShow">
                    <Tab.Pane eventKey="first">
                      <div
                        data-aos="fade"
                        data-aos-easing="ease-in"
                        className="alignLeftSecond"
                      >
                        <img
                          alt="codecartel we do"
                          src={illustration01}
                          className="img-fluid styleWwd"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration02}
                          title="codecartel we do"
                          className="img-fluid styleWwd"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration03}
                          title="codecartel we do"
                          className="img-fluid styleWwd3"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration04}
                          title="codecartel we do"
                          className="img-fluid styleWwd4"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration05}
                          title="codecartel we do"
                          className="img-fluid styleWwd5"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration06}
                          title="codecartel we do"
                          className="img-fluid styleWwd6"
                        ></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <div className="alignLeftSecond">
                        <img
                          alt="codecartel we do"
                          src={illustration07}
                          title="codecartel we do"
                          className="img-fluid styleWwd7"
                        ></img>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  {/*  */}
                  <Nav variant="pills" className="flex-column">
                    <Row className="styleRowBottom">
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="first">
                            Branding
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="second">
                            Development
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Row>
                    <Row className="styleRowBottom">
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="third">
                            Marketing
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="fourth">
                            UI Design
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Row>
                    <Row className="styleRowBottom">
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="fifth">
                            UX Design
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col sm={6} xs={6} md={6} lg={6} xl={6}>
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="sixth">
                            Cloud Services
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                      <Col
                        className="spaceTopSecond"
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <Nav.Item>
                          <Nav.Link className="styleNavTab" eventKey="seventh">
                            Support & Maintenance
                          </Nav.Link>
                        </Nav.Item>
                      </Col>
                    </Row>
                  </Nav>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </section>
      <section className="fourthFold">
        <Container>
          <div className="spaceDiv">
            <h2 className="mainHead">Services we offer</h2>
            <p className="subPara">
              We’re a multi-disciplined lot, with skills ranging from digital
              illustration to international business strategy to hardcore
              programming. We believe it takes a diverse and multifaceted
              approach to succeed in our connected world.
            </p>
          </div>

          <div className="cardMain">
            <Row className="spaceServiceRow">
              <Col sm={12} xs={12} md={4} lg={4} xl={4} 
                  data-aos="fade-up"
                  data-aos-duration="3000">
                <div
                  className="cardServices custom-color fadeInUp animated"
                >
                  <img src={uxDesign}  title="uxdesign" alt="uxdesign codecartel"></img>
                  <h5 className="servicesName">UX DESIGN</h5>
                  <p className="servicesPoints">User Research</p>
                  <p className="servicesPoints">Heuristic Evaluation</p>
                  <p className="servicesPoints">Persona Development</p>
                  <p className="servicesPoints">User Journey Mapping</p>
                  <p className="servicesPoints">IA Validation</p>
                  <p className="servicesPoints">Wireframing</p>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}  data-aos="fade-up"
                  data-aos-duration="3000">
                <div
                  className="cardServices custom-color fadeInUp animated"
                >
                  <img src={uiDesign} title="uidesign" alt="uidesign codecartel"></img>
                  <h5 className="servicesName">UI DESIGN</h5>
                  <p className="servicesPoints">Visual Design</p>
                  <p className="servicesPoints">Interaction Design</p>
                  <p className="servicesPoints">Digital Brand Development</p>
                  <p className="servicesPoints">Rapid Prototyping</p>
                  <p className="servicesPoints">Design Systems</p>
                  <p className="servicesPoints">Styleguide</p>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}  data-aos="fade-up"
                  data-aos-duration="3000">
                <div
                  className="cardServices custom-color fadeInUp animated"
                >
                  <img src={Development} title="development" alt="development codecartel"></img>
                  <h5 className="servicesName">DEVELOPMENT</h5>
                  <p className="servicesPoints">Custom Web Development</p>
                  <p className="servicesPoints">Mobile App Development</p>
                  <p className="servicesPoints">Web Apps</p>
                  <p className="servicesPoints">Content Management System</p>
                  <p className="servicesPoints">eCommerce Development</p>
                  <p className="servicesPoints">Support & Maintenance</p>
                </div>
              </Col>
            </Row>
            <Row>
            <Col sm={12} xs={12} md={2} lg={2} xl={2}></Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}  data-aos="fade-up"
                  data-aos-duration="3000">
                <div
                  className="cardServices custom-color fadeInUp animated"
                >
                  <img src={Branding} title="branding" alt="branding codecartel"></img>
                  <h5 className="servicesName">BRANDING</h5>
                  <p className="servicesPoints">Brand Development</p>
                  <p className="servicesPoints">Rebranding</p>
                  <p className="servicesPoints">Logo & Visual Identity</p>
                  <p className="servicesPoints">Collateral Design</p>
                  <p className="servicesPoints">Illustrations</p>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}  data-aos="fade-up"
                  data-aos-duration="3000">
                <div
                  className="cardServices custom-color fadeInUp animated"
                >
                  <img src={Marketing} title="marketing" alt="marketing codecartel"></img>
                  <h5 className="servicesName">MARKETING</h5>
                  <p className="servicesPoints">SEO</p>
                  <p className="servicesPoints">Google Ads</p>
                  <p className="servicesPoints">Social Media Management</p>
                  <p className="servicesPoints">Email Marketing</p>
                  <p className="servicesPoints">Digital Marketing</p>
                </div>
              </Col>
              <Col sm={12} xs={12} md={2} lg={2} xl={2}>
                {/* <div
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  className="cardServices"
                >
                  <img src={Branding} alt="branding codecartel"></img>
                  <h5 className="servicesName">BRANDING</h5>
                  <p className="servicesPoints">Brand Development</p>
                  <p className="servicesPoints">Rebranding</p>
                  <p className="servicesPoints">Logo & Visual Identity</p>
                  <p className="servicesPoints">Collateral Design</p>
                  <p className="servicesPoints">Illustrations</p>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="fifthFold">
        <Container>
        <div className="spaceBottomFifth">
            <h2 className="mainHeadLight center">Our Superpowers</h2>
            <p className="subheadSecond center">
            Aesthetics and function are equally important to us - we create efficient brands and compelling products
            </p>
        </div>
        <Row>
        <Col className="indexUp" sm={12} xs={12} md={1} lg={1} xl={1}>
            <div className="superPowersLeft"> 
              <img data-aos="fade" data-aos-duration="3000" alt="naman" title="naman" src={left} className="leftMen"></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={10} lg={10} xl={10}>
            <div className="superPowersBoard"> 
            <Row>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                  <div className="mViewSpace">
                    <img alt="focus on goals" title="goals"  data-aos="fade-up" data-aos-duration="3000" src={cardSuperPower1} className="fluidSuperpowers"></img>
                  </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                <div className="mViewSpace">
                  <img alt="process" title="process" data-aos="fade-up" data-aos-duration="3000" src={cardSuperPower2} className="fluidSuperpowers"></img>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                <div>
                  <img alt="delivery time" title="time" data-aos="fade-up" data-aos-duration="3000" src={cardSuperPower3} className="fluidSuperpowers"></img>
                </div>
              </Col>
            </Row>
            </div>
            <div className="center">
              <img alt="stand" title="stand" src={stand}></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={1} lg={1} xl={1}>
            <div className="superPowersRight"> 
              <img alt="chetan" title="chetan" data-aos="fade" data-aos-duration="3000" src={right} className="rightMen"></img>
            </div>
          </Col>
        </Row>
        </Container>
      </section>

      <section className="sixthFold">
        <Container>
          <div className="spaceDiv">
            <h2 className="mainHead">Testimonials</h2>
            <div className="layerRunLeft"></div>
            <p className="subPara">
              We’re a multi-disciplined lot, with skills ranging from digital
              illustration to international business strategy to hardcore
              programming. We believe it takes a diverse and multifaceted
              approach to succeed in our connected world.
            </p>
          </div>
          <div>
            <Row>
              <Col sm={12} xs={12} md={5} lg={5} xl={5}>
                <div data-aos="fade" data-aos-duration="2000" className="leftCard">
                  <p className="leftCardTxt">
                    Helping <span className="yellowBig">Brands </span>
                    stand out in a
                    <span className="yellowBig"> Saturated world</span>
                  </p>
                </div>
              </Col>
              <Col sm={12} xs={12} md={7} lg={7} xl={7}>
                <div data-aos="fade-up" data-aos-duration="3000" className="rightCard">
                  <Carousel>
                    <Carousel.Item interval={3000}>
                      <img
                        className="d-block w-100 img-fluid quoteStyle"
                        src={Quote}
                        alt="Quote"
                      />
                      <div className="txtDiv">
                        <p className="clientName">Aditya Pandit</p>
                        <p className="ClientsDesignation">
                          Co-Founder & Director | Hutah Industries Pvt LTD
                        </p>
                        <p className="paraTestimonials">
                        Codecartel is probably the best UI/UX Design studio we've worked with. Apart from being professional and highly creative, their team brought an approach we hadn't seen before. Always focused on data & results, Codecartel had us work our concepts and vision backward; designing for the outcome. Highly recommend them for any digital design work.{" "}
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img
                        className="d-block w-100 img-fluid quoteStyle"
                        src={Quote}
                        alt="Quote"
                      />
                      <div className="txtDiv">
                        <p className="clientName">Santosh Singh Raghuwanshi</p>
                        <p className="ClientsDesignation">
                          System Network Administrator | Nice Software Solutions Pvt LTD
                        </p>
                        <p className="paraTestimonials">
                        It was such a pleasure to work with Codecartel. They understood everything we were trying to accomplish and took it to the next level. They are highly skilled at what they do, think outside the box and provide excellent well thought out work. I would highly recommend Codecartel and hope to work with them again soon.{" "}
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-fluid quoteStyle"
                        src={Quote}
                        alt="Quote"
                      />
                      <div className="txtDiv">
                        <p className="clientName">Utkarsh Agrawal</p>
                        <p className="ClientsDesignation">
                          Co-Founder & Creative Director | Make Believe
                        </p>
                        <p className="paraTestimonials">
                        Awesome work. They did a fantastic job creating a premium feel on our web apps and design language overall.{" "}
                        </p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-fluid quoteStyle"
                        src={Quote}
                        alt="Quote"
                      />
                      <div className="txtDiv">
                        <p className="clientName">Aakash Reddy</p>
                        <p className="ClientsDesignation">
                          Founder | Sketch My Plot
                        </p>
                        <p className="paraTestimonials">
                          What we really enjoyed about Codecartel was for such a multi-tiered and complicated project, they never once expressed any ounce of limitations or hesitations. They were wonderful collaborators and problem solvers; and we felt well taken care of every step of the way.{" "}
                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="clientsLogo">
            <h3 className="trustedByHead">
              OUR HAPPY CLIENTS
            </h3>
            <p className="subHeadTestimonials">From startups to the most recognizable companies in the world</p>
            <div className="layerRun"></div>
            <div className="emptySpace"></div>
            <div className="clients-slideshow-main slidelefttop-r"></div>
            <div className="clients-slideshow-main slideright-r"></div>
            <div className="clients-slideshow-main slideleft-r"></div>
          </div>
      </section>
      <section className="ctaDiv">
        <Container>
          <Row>
          <Col className="mobileViewShow" sm={12} xs={12} md={6} lg={6} xl={6}>
              <div>
                <img
                  src={mobileIllustration}
                  className="ctaImage" alt="mobile codecartel" title="mobile"
                ></img>
              </div>
            </Col>
            <Col sm={12} xs={12} md={6} lg={6} xl={6}>
              <div className="spaceTopCta">
                <h2 data-aos="fade-in" data-aos-duration="2000" className="taglineMain">
                  All of your <span className="colorBlue">digital needs</span>, harmoniously <span>in one place</span>.
                </h2>
                <p data-aos="fade-in" data-aos-duration="2000" className="lastPara">
                  Focusing on creativity to bolster businesses & brands in an
                  increasingly digital world.
                </p>
              </div>
            </Col>
            <Col className="desktopViewShow" sm={12} xs={12} md={6} lg={6} xl={6}>
              <div>
                <img
                  src={mobileIllustration}
                  className="ctaImage" alt="mobile codecartel" title="mobile"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </div>
  );
}

export default Home;
