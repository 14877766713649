import React, { useEffect, useRef }  from 'react';
import './Work.css';
import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Row, Col} from 'react-bootstrap';
import development from "../Assets/services/development.svg";
import star from "../Assets/work/star.svg";
import cyborg from "../Assets/work/cyborg.svg";
import Gallery from './Gallery';
import cube from "../Assets/cube1.json";
import gridUpper from "../Assets/gridUpper.svg";

function Work() {

 
    return (
        
        <div>
          <section className='mountainBg'>
              {/* <img src={gridUpper} className="gridUpperNeon"></img> */}
                <Container>
                    <div>
                        <Row className='spaceTopWork'>
                            <Col className='Mview' lg={3} sm={12}>
                                <div className='style_cubes'>
                                    <Player className="styleCube_Height" autoplay loop src={cube}></Player>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div>
                                    <h3 className='workHead'>A small selection of <span className='white_color'>Codecartel</span> projects from Creative Design to Development
                                    </h3>
                                    <p className='highlight_light'></p>
                                    <p className='workPara'>We create showcase websites, activations, campaigns, digital experiences, apps, progressive web apps (PWA), AR experiences, filters, lenses, 360° live games, swipe-ups, and more. We don’t sell ready-made templates or formulas, but unique craft, original adventures that will captivate your audiences.</p>
                                </div>
                            </Col>
                            <Col lg={3} >
                                <div className='style_cubes Dview'>
                                    <Player className="styleCube_Height" autoplay loop src={cube}></Player>
                                </div>
                            </Col>
                        </Row>
                        
                    </div>
                </Container>
          </section>
            <div className="marquee">
                <div className='leftD'>
                    <p>Code with creativity, build with purpose.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>Code with creativity, build with purpose.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>Code with creativity, build with purpose.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>Code with creativity, build with purpose.</p>
                </div>
            </div>
            <section className='gridBg'>
                <Container>
                    {/* <Gallery/> */}
                    <div className='shipanimate'>
                        
                    </div>
                </Container>
            </section>
            <div className="marquee">
                <div className='leftD'>
                    <p>A better world, by design.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>A better world, by design.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>A better world, by design.</p>
                    <img alt='star' className='starStyle' src={star}></img>
                    <p>A better world, by design.</p>
                </div>
            </div>
        </div>
    )
}

export default Work